// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Customize your theme here
  mixins: {
    toolbar: {
      minHeight: 64, // or any value you need
    },
  },
});

export default theme;
