import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';  // Import Framer Motion for animations
import CallMadeIcon from '@mui/icons-material/CallMade';
import image1 from "../images/download.png";   // Importing the images
import image2 from "../images/download (1).png";
import image3 from "../images/download (2).png";
import { TypeAnimation } from 'react-type-animation';  // Import react-type-animation

// Custom component for each service card
const ServiceCard = ({ titleLines, description, image }) => {
  // Swim effect for cards
  const swimEffect = {
    animate: {
      x: [0, 10, -10, 0],  // Move horizontally back and forth
      y: [0, -5, 5, 0],    // Move vertically like waves
    },
    transition: {
      duration: 4,
      ease: "easeInOut",
      repeat: Infinity,  // Infinite loop to mimic "swimming"
      repeatType: "mirror",  // Reflect movement back and forth
    },
  };

  return (
    <motion.div
      whileHover={{ scale: 1.05, rotate: [0, 3, -3, 0] }}  // Rotate and scale on hover
      initial={{ opacity: 0, y: 100 }}  // Initial fade in from below
      animate={{ opacity: 1, y: 0 }}    // Animate to visible
      transition={{ duration: 0.5, ease: 'easeOut' }}  // Animation timing
    >
      <motion.div {...swimEffect}>  {/* Apply the swim effect */}
        <Box
          sx={{
            backgroundColor: '#141718',
            padding: '40px',
            borderRadius: '10px',
            border: '2px solid #D4FF00',  // Border color matching the image
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#ccdeff',
            width: '90%',  // Set card width to 90%
            margin: 'auto', // Center the card
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', fontSize: '1.5rem' }}>
              {titleLines.map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', color: '#c7d9f9', fontSize: '1.2rem' }}>
              {description}
            </Typography>
          </Box>

          {/* Larger Image */}
          <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-start' }}>
            <img src={image} alt={description} style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
          </Box>

          {/* Bottom arrow with custom styling */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <CallMadeIcon sx={{ 
              color: '#D4FF00', 
              fontSize: '4rem', 
              marginBottom: '-10px',
              cursor: 'pointer',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.2)',  // Enlarge the arrow on hover
              },
            }} />
          </Box>
        </Box>
      </motion.div>
    </motion.div>
  );
};

// Main component
const ServicesSection = () => {
  return (
    <Box sx={{ backgroundColor: '#0d0f10', padding: '50px 20px' }}>
      {/* Top Title Section */}
      <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
        <Typography
          variant="h6"
          sx={{
            color: '#D4FF00',
            letterSpacing: '4px',
            marginBottom: '10px',
          }}
        >
          WHAT WE OFFER
        </Typography>

        {/* Typewriter Effect using react-type-animation */}
        <Typography
          variant="h4"
          sx={{
            color: '#ccdeff',
            fontWeight: 'bold',
            letterSpacing: '2px',
          }}
        >
          <TypeAnimation
            sequence={[
              "We Offer A Wide Range Of",    // Text
              1000,                          // Pause for 1 second
              "Security Systems",            // Next text
              1000,                          // Pause for 1 second
              "Web Applications",                    // Next text
              1000,                          // Pause for 1 second
              "Mobile Applications",         // Next text
              1000,                          // Pause for 1 second
              "Software ",             // Next text
              1000,   
              "Artificial Intelligence ",             // Next text
              1000,                        // Pause for 1 second
              () => {                        // Callback after sequence completes
                console.log("Animation completed!"); // Optional: do something
              },
            ]}
            wrapper="span"
            cursor={true}                   // Show cursor
            repeat={Infinity}               // Repeat the animation infinitely
            style={{ display: "inline-block" }} // Keep inline styling for text
          />
        </Typography>
      </Box>

      {/* Cards Section */}
      <Grid style={{width:"90%",margin:"auto"}} container spacing={4}>
        {/* Card 1 */}
        <Grid style={{cursor:"pointer"}} item xs={12} md={4}>
          <ServiceCard
            titleLines={[
              "UI/UX Design, Motion,",
              "Illustration, Branding,",
              "Photography"
            ]}
            description="Design & UI/UX Experience"
            image={image1}   // Use image1 for this card
          />
        </Grid>

        {/* Card 2 */}
        <Grid style={{cursor:"pointer"}} item xs={12} md={4}>
          <ServiceCard
            titleLines={[
              "WordPress, Shopify,",
              "Webflow, React, HTML,",
              "Mobile Solutions"
            ]}
            description="Development & Coding"
            image={image2}   // Use image2 for this card
          />
        </Grid>

        {/* Card 3 */}
        <Grid style={{cursor:"pointer"}} item xs={12} md={4}>
          <ServiceCard
            titleLines={[
              "Social Solutions,",
              "Marketing & Targeting,",
              "SEO & SMM"
            ]}
            description={(
              <Box>
                Marketing & SEO Analytic
              </Box>
            )}
            image={image3}   // Use image3 for this card
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesSection;
