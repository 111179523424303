import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/system";
import CodeIcon from "@mui/icons-material/Code";
import BrushIcon from "@mui/icons-material/Brush";
import WebIcon from "@mui/icons-material/Web";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import StorageIcon from "@mui/icons-material/Storage";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LeadershipIcon from "@mui/icons-material/EmojiEvents";
import CommunicationIcon from "@mui/icons-material/Forum";
import CreativityIcon from "@mui/icons-material/Lightbulb";

// Styled components for progress bar animation
const AnimatedProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20, // Thicker sliders
  borderRadius: 5,
  backgroundColor: "#e0e0e0",
  overflow: "hidden",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#6fd943", // Slider color
    animation: `1.5s forwards ease-in-out`,
  },
}));

// Styled component for skill icons with hover animation
const SkillIcon = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const EmployeeDetail = () => {
  const { id } = useParams(); // Employee ID from the route
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [skills, setSkills] = useState([]);
  const [disciplineSkills, setDisciplineSkills] = useState([]); // State for discipline skills
  const [globalHierarchy, setGlobalHierarchy] = useState([]);
  const [departmentHierarchy, setDepartmentHierarchy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState(null);
  const [dialogType, setDialogType] = useState(""); // Add a state for dialog type
  const [acceptedLeaveCount, setAcceptedLeaveCount] = useState(0);
  const [leaveRequests, setLeaveRequests] = useState([]); // Store all leave requests for the current month
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null); // Store the selected leave request
  const [permissions, setPermissions] = useState([]); // Store all permissions for the current month
  const [selectedPermission, setSelectedPermission] = useState(null); // Store the selected permission
  const [attendanceRecords, setAttendanceRecords] = useState([]); // State for attendance records
  const [deductions, setDeductions] = useState([]); // State for deductions

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    if (employee) {
      fetchGlobalHierarchy();
      fetchDepartmentHierarchy(employee.department2);
      fetchEmployeeSkills(employee.employee_id); // Fetch skills for the employee
      fetchDisciplineSkills(employee.employee_id); // Fetch discipline skills
      fetchLeaveRequests(employee.username); // Fetch leave requests for the employee
      fetchPermissions(employee.username); // Fetch permissions for the employee
      fetchAttendanceRecords(employee.username); // Fetch attendance records for the employee
    }
  }, [employee]);

  const fetchEmployee = async () => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getemployee.php?id=${id}`
      );
      const result = await response.json();
      if (result.status === "success") {
        const employeeData = result.data;
        employeeData.certificates = JSON.parse(
          employeeData.certificates || "[]"
        );
        setEmployee(employeeData);
      } else {
        setError("Failed to fetch employee: " + result.message);
      }
    } catch (error) {
      setError("Error fetching employee: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeSkills = async (employeeId) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getSkills.php?employeeId=${employeeId}`
      );
      const result = await response.json();
      if (result.success) {
        setSkills(result.skills);
      } else {
        console.error("Failed to fetch skills:", result.message);
      }
    } catch (error) {
      console.error("Error fetching skills:", error.message);
    }
  };

  const fetchDisciplineSkills = async (employeeId) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getDisciplineSkills.php?employeeId=${employeeId}`
      );
      const result = await response.json();
      if (result.success) {
        setDisciplineSkills(result.skills);
      } else {
        console.error("Failed to fetch discipline skills:", result.message);
      }
    } catch (error) {
      console.error("Error fetching discipline skills:", error.message);
    }
  };

  const fetchGlobalHierarchy = async () => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/gethierarchy.php?global=true`
      );
      const result = await response.json();
      if (result.status === "success") {
        setGlobalHierarchy(result.data);
      } else {
        setError("Failed to fetch global hierarchy: " + result.message);
      }
    } catch (error) {
      setError("Error fetching global hierarchy: " + error.message);
    }
  };

  const fetchDepartmentHierarchy = async (department) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/gethierarchy.php?department=${department}`
      );
      const result = await response.json();
      if (result.status === "success") {
        setDepartmentHierarchy(result.data);
      } else {
        setError("Failed to fetch department hierarchy: " + result.message);
      }
    } catch (error) {
      setError("Error fetching department hierarchy: " + error.message);
    }
  };

  const fetchLeaveRequests = async (username) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getleaveRequest.php`
      );
      const result = await response.json();
      if (result.success) {
        const currentMonth = new Date().getMonth() + 1; // Get the current month (0-based index, so add 1)
        const currentYear = new Date().getFullYear(); // Get the current year

        const filteredRequests = result.data.filter((request) => {
          const startDate = new Date(request.start_date);
          return (
            request.username === username &&
            request.is_accepted === 1 &&
            startDate.getMonth() + 1 === currentMonth && // Check if the month matches
            startDate.getFullYear() === currentYear // Check if the year matches
          );
        });
        setLeaveRequests(filteredRequests); // Store filtered requests
        setAcceptedLeaveCount(filteredRequests.length);
      } else {
        console.error("Failed to fetch leave requests:", result.message);
      }
    } catch (error) {
      console.error("Error fetching leave requests:", error.message);
    }
  };

  const fetchPermissions = async (username) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/getPermissions.php`
      );
      const result = await response.json();
      if (result.success) {
        const filteredPermissions = result.data.filter(
          (permission) => permission.username === username && permission.is_accepted === 1
        );
        setPermissions(filteredPermissions);
      } else {
        console.error("Failed to fetch permissions:", result.message);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const fetchAttendanceRecords = async (username) => {
    try {
      const response = await fetch(
        `https://backendforerpsystem.zbooma.com/get_attendance_month.php`
      );
      const result = await response.json();
      if (result.success) {
        const userAttendance = result.records.filter(
          (record) => record.username === username
        );
        setAttendanceRecords(userAttendance);
        calculateDeductions(userAttendance); // Calculate deductions based on attendance
      } else {
        console.error("Failed to fetch attendance records:", result.message);
      }
    } catch (error) {
      console.error("Error fetching attendance records:", error.message);
    }
  };

  const calculateDeductions = (records) => {
    const deductions = [];
    records.forEach((record) => {
      const attendanceTime = new Date(`1970-01-01T${record.time}Z`);
      const thresholdTime1 = new Date(`1970-01-01T09:16:00Z`);
      const thresholdTime2 = new Date(`1970-01-01T09:30:00Z`);
  
      if (attendanceTime > thresholdTime1 && attendanceTime <= thresholdTime2) {
        // Late between 9:16 and 9:30, deduct quarter day
        const quarterDayDeduction = (employee.salary / 30) / 4;
        deductions.push({
          date: record.date,
          time: record.time,
          deduction: quarterDayDeduction.toFixed(2),
          reason: "مخصوم ربع يوم",
        });
      } else if (attendanceTime > thresholdTime2) {
        // Late after 9:30, deduct half day
        const halfDayDeduction = (employee.salary / 30) / 2;
        deductions.push({
          date: record.date,
          time: record.time,
          deduction: halfDayDeduction.toFixed(2),
          reason: "مخصوم نصف يوم",
        });
      }
    });
    setDeductions(deductions);
  };
  

  const handleLeaveRequestClick = (leaveRequest) => {
    setSelectedLeaveRequest(leaveRequest);
    setSelectedPermission(null); // Clear selected permission
    setCurrentCertificate(null); // Clear the current certificate
    setDialogType("leaveRequest"); // Set the dialog type
    setOpenDialog(true);
  };

  const handlePermissionClick = (permission) => {
    setSelectedPermission(permission);
    setSelectedLeaveRequest(null); // Clear selected leave request
    setCurrentCertificate(null); // Clear the current certificate
    setDialogType("permission"); // Set the dialog type
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedLeaveRequest(null);
    setSelectedPermission(null);
    setCurrentCertificate(null);
    setDialogType(""); // Reset the dialog type
  };

  const handleOpenDialog = (certificate) => {
    setCurrentCertificate(certificate);
    setSelectedLeaveRequest(null); // Clear selected leave request
    setSelectedPermission(null); // Clear selected permission
    setDialogType("certificate"); // Set the dialog type
    setOpenDialog(true);
  };

  const roleOrder = ["Head", "Team Leader", "Senior", "Mid-Level", "Junior"];

  const getRoleOrder = (role) => {
    return roleOrder.indexOf(role);
  };

  const sortedHierarchy = [...departmentHierarchy].sort((a, b) => {
    return getRoleOrder(a.role) - getRoleOrder(b.role);
  });

  const canPreviewFile = (filename) => {
    return (
      filename.endsWith(".pdf") ||
      filename.endsWith(".docx") ||
      filename.endsWith(".ppt") ||
      filename.endsWith(".pptx")
    );
  };

  const calculateHoursDifference = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);
    const diff = (end - start) / 1000 / 60 / 60; // difference in hours
    return diff.toFixed(2);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!employee) {
    return <div>No employee data available</div>;
  }

  const cardStyle = {
    padding: "16px",
    margin: "8px",
    borderRadius: "10px",
    boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
    direction: "rtl",
    fontFamily: "Cairo, sans-serif",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align content to the start
    justifyContent: "flex-start", // Justify content at the start
    textAlign: "right",
  };
  
  const cardStyle2 = {
    padding: "16px",
    margin: "8px",
    borderRadius: "10px",
    boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
    direction: "rtl",
    fontFamily: "Cairo, sans-serif",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align content to the start
    justifyContent: "flex-start", // Justify content at the start
    textAlign: "center",
    width: "300px", // Fixed width (optional, you can adjust or remove it)
  };
  

  const activeCardStyle = {
    ...cardStyle2,
    border: "2px solid #6fd943",
  };

  const headerStyle = {
    color: "#6fd943",
    fontFamily: "Cairo, sans-serif",
    textAlign: "right",
    marginBottom: "16px",
    direction: "rtl",
  };

  const typographyStyle = {
    color: "gray",
    fontFamily: "Cairo, sans-serif",
    textAlign: "right",
    marginBottom: "8px",
    direction: "rtl",
  };

  const keyStyle = {
    color: "#6fd943", // Change to desired color
    fontWeight: "bold",
    display: "inline", // Ensure the key is inline with the value
    marginRight: "4px", // Add some space between the key and the value
    textAlign:"right"
  };

  return (
    <div style={{ padding: "16px", direction: "rtl" }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <Typography
          variant="h4"
          style={{
            ...headerStyle,
            flexGrow: 1,
            color: "#6fd943",
          }}
        >
          تفاصيل الموظف
        </Typography>
        <IconButton
          onClick={() => navigate(-1)}
          style={{
            backgroundColor: "#6fd943",
            color: "#ffffff",
            borderRadius: "50%",
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Paper
            style={{
              ...cardStyle,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Avatar
                alt={employee.name}
                src={`https://backendforerpsystem.zbooma.com/${employee.photo}`}
                style={{ width: "80px", height: "80px", marginBottom: "8px" }}
              />
              <Typography
                variant="h6"
                style={{ ...headerStyle, textAlign: "center" }}
              >
                {employee.name}
              </Typography>
              <Typography
                variant="body1"
                style={{ ...typographyStyle, textAlign: "center" }}
              >
                <span style={keyStyle}>المنصب:</span> {employee.role}
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={headerStyle}>
              تفاصيل الشركة
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>فرع:</span> {employee.branch}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>الادارة:</span> {employee.department}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>القسم:</span> {employee.department2}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>التسمية:</span> {employee.role}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>تاريخ الانضمام:</span>{" "}
              {employee.joining_date}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} container spacing={3} alignItems="stretch">
          <Grid item xs={12}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={headerStyle}>
                تفاصيل شخصية
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>كود الموظف:</span> {employee.employee_code}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>الاسم:</span> {employee.name}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>تاريخ الميلاد:</span>{" "}
                {employee.date_of_birth}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>البريد الالكتروني:</span> {employee.email}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>العنوان:</span> {employee.address}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>الجنس:</span> {employee.gender}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>الراتب:</span> {employee.salary}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={headerStyle}>
              تفاصيل الحساب المصرفي
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>رقم الحساب:</span> {employee.account_number}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>اسم صاحب الحساب:</span>{" "}
              {employee.account_holder_name}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>اسم البنك:</span> {employee.bank_name}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>كود تعريف البنك:</span>{" "}
              {employee.bank_identifier_code}
            </Typography>
            <Typography variant="body1" style={typographyStyle}>
              <span style={keyStyle}>كود دافع الضريبة:</span> {employee.tax_payer_id}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              التسلسل الهرمي
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              {roleOrder.map((role, index) => {
                const currentRoleEmployees = sortedHierarchy.filter(
                  (person) => person.role === role
                );
                const nextRole = roleOrder[index + 1];
                const hasNextRoleEmployees = sortedHierarchy.some(
                  (person) => person.role === nextRole
                );

                return (
                  <React.Fragment key={role}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      flexWrap="wrap"
                    >
                      {currentRoleEmployees.map((person, personIndex) => (
                        <Paper
                          key={personIndex}
                          style={
                            person.name === employee.name
                              ? activeCardStyle
                              : cardStyle2
                          }
                        >
                          <Avatar
                            alt={person.name}
                            src={`https://backendforerpsystem.zbooma.com/${person.photo}`}
                            style={{
                              width: "40px",
                              height: "40px",
                              marginBottom: "8px",
                            }}
                          />
                          <Typography variant="body1" style={typographyStyle}>
                            {person.name}
                          </Typography>
                          <Typography variant="body2" style={typographyStyle}>
                            {person.role}
                          </Typography>
                        </Paper>
                      ))}
                    </Box>
                    {hasNextRoleEmployees && index < roleOrder.length - 1 && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <ArrowDownwardIcon
                          style={{ color: "#6fd943", fontSize: "30px" }}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
            </Box>
          </Paper>
        </Grid>

        {/* New Section for Skills */}
        <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              المهارات التقنية
            </Typography>
            <Grid container spacing={2}>
              {skills.map((skill, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Tooltip
                    title={`${skill.skill_name} proficiency level`}
                    arrow
                  >
                    <Box display="flex" alignItems="center">
                      <SkillIcon style={{ cursor: "pointer" }}>
                        {getSkillIcon(skill.skill_name)}
                      </SkillIcon>
                      <Typography
                        variant="body1"
                        style={{
                          fontFamily: "Cairo, sans-serif",
                          marginBottom: "4px",
                          color: "gray",
                          width: "120px",
                          flexShrink: 0,
                        }}
                      >
                        {skill.skill_name}
                      </Typography>
                      <Box flexGrow={1} mx={2}>
                        <AnimatedProgress
                          variant="determinate"
                          value={skill.percentage}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Cairo, sans-serif",
                          color: "gray",
                          marginTop: "4px",
                          flexShrink: 0,
                        }}
                      >
                        {skill.percentage}%
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* New Section for Professional Skills */}
        <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              المهارات الشخصية والانضباطية
            </Typography>
            <Grid container spacing={2}>
              {disciplineSkills.map((skill, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Tooltip
                    title={`${skill.skill_name} proficiency level`}
                    arrow
                  >
                    <Box display="flex" alignItems="center">
                      <SkillIcon style={{ cursor: "pointer" }}>
                        {getProfessionalSkillIcon(skill.skill_name)}
                      </SkillIcon>
                      <Typography
                        variant="body1"
                        style={{
                          fontFamily: "Cairo, sans-serif",
                          marginBottom: "4px",
                          color: "gray",
                          width: "120px",
                          flexShrink: 0,
                        }}
                      >
                        {skill.skill_name}
                      </Typography>
                      <Box flexGrow={1} mx={2}>
                        <AnimatedProgress
                          variant="determinate"
                          value={skill.percentage}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: "Cairo, sans-serif",
                          color: "gray",
                          marginTop: "4px",
                          flexShrink: 0,
                        }}
                      >
                        {skill.percentage ? `${skill.percentage}%` : "لا توجد قيمة"}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* New Section for Leave Requests */}
        <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              طلبات الإجازة
            </Typography>
            {leaveRequests.length > 0 ? (
              leaveRequests.map((request, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  mt={2}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLeaveRequestClick(request)}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      marginRight: "8px",
                      color: "#6fd943",
                    }}
                  >
                    {`طلب إجازة ${index + 1}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      color: "gray",
                    }}
                  >
                    ({request.start_date} - {request.end_date})
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Cairo, sans-serif",
                  color: "gray",
                  marginBottom: "8px",
                }}
              >
                هذا المستخدم ليس لديه طلبات إجازة مقبولة لهذا الشهر.
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* New Section for Permissions */}
        <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              الاذونات
            </Typography>
            {permissions.length > 0 ? (
              permissions.map((permission, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  mt={2}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePermissionClick(permission)}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      marginRight: "8px",
                      color: "#6fd943",
                    }}
                  >
                    {`اذن ${index + 1}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      color: "gray",
                    }}
                  >
                    ({permission.start_time} - {permission.end_time}) - {calculateHoursDifference(permission.start_time, permission.end_time)} ساعات
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Cairo, sans-serif",
                  color: "gray",
                  marginBottom: "8px",
                }}
              >
                هذا المستخدم ليس لديه اذونات مقبولة.
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* New Section for Deductions */}
 {/* New Section for Deductions */}
<Grid item xs={12}>
  <Paper style={{ ...cardStyle, padding: "24px" }}>
    <Typography variant="h6" style={headerStyle}>
      الخصومات
    </Typography>
    {deductions.length > 0 ? (
      deductions.map((deduction, index) => (
        <Box key={index} display="flex" alignItems="center" mt={2}>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Cairo, sans-serif",
              marginRight: "8px",
              color: "red",
            }}
          >
            {`خصم ${deduction.reason}`}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: "Cairo, sans-serif",
              color: "gray",
            }}
          >
            {`(${deduction.date} - ${deduction.time}) - ${deduction.deduction} جنيه`}
          </Typography>
        </Box>
      ))
    ) : (
      <Typography
        variant="body1"
        style={{
          fontFamily: "Cairo, sans-serif",
          color: "gray",
          marginBottom: "8px",
        }}
      >
        لا توجد خصومات لهذا الشهر.
      </Typography>
    )}
  </Paper>
</Grid>


        {/* New Section for Documents and Certificates */}
        <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              الوثائق والشهادات
            </Typography>
            {Array.isArray(employee.certificates) &&
            employee.certificates.length > 0 ? (
              employee.certificates.map((certificate, index) => (
                <Box key={index} display="flex" alignItems="center" mt={2}>
                  {canPreviewFile(certificate) && (
                    <Button
                      onClick={() => handleOpenDialog(certificate)}
                      variant="contained"
                      color="primary"
                      startIcon={
                        <VisibilityIcon style={{ marginLeft: "8px" }} />
                      }
                      style={{
                        marginRight: "8px",
                        backgroundColor: "#6fd943",
                        fontFamily: "cairo",
                      }}
                    >
                      معاينة
                    </Button>
                  )}
                  <IconButton
                    href={`https://backendforerpsystem.zbooma.com/${certificate}`}
                    download
                    style={{ color: "#6fd943" }}
                    aria-label="download certificate"
                  >
                    <DescriptionIcon />
                  </IconButton>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      marginLeft: "8px",
                    }}
                  >
                    <a
                      href={`https://backendforerpsystem.zbooma.com/${certificate}`}
                      download
                      style={{ color: "#6fd943", textDecoration: "none" }}
                    >
                      تحميل الشهادة
                    </a>
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  color: "gray",
                  fontFamily: "Cairo, sans-serif",
                  marginBottom: "8px",
                }}
              >
                لا توجد وثائق أو شهادات مسجلة.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Dialog for Leave Request, Permission Details, and Certificate Preview */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        style={{ textAlign: "right" }}
      >
        <DialogTitle
          style={{ fontFamily: "Cairo, sans-serif", textAlign: "center" }}
        >
          {dialogType === "leaveRequest" && "تفاصيل طلب الإجازة"}
          {dialogType === "permission" && "تفاصيل الإذن"}
          {dialogType === "certificate" && "معاينة الشهادة"}
        </DialogTitle>
        <DialogContent style={{ height: "500px" }}>
          {selectedLeaveRequest && (
            <div>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>اسم الموظف:</span> {selectedLeaveRequest.employee_name}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>القسم:</span> {selectedLeaveRequest.department}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>نوع الإجازة:</span> {selectedLeaveRequest.leave_type}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>سبب الإجازة:</span> {selectedLeaveRequest.leave_reason}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>تاريخ البدء:</span> {selectedLeaveRequest.start_date}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>تاريخ الانتهاء:</span> {selectedLeaveRequest.end_date}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>تاريخ الإنشاء:</span> {selectedLeaveRequest.created_at}
              </Typography>
            </div>
          )}
          {selectedPermission && (
            <div>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>اسم الموظف:</span> {selectedPermission.employee_name}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>القسم:</span> {selectedPermission.department}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>نوع الإذن:</span> {selectedPermission.permission_type}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>سبب الإذن:</span> {selectedPermission.permission_reason}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>وقت البدء:</span> {selectedPermission.start_time}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>وقت الانتهاء:</span> {selectedPermission.end_time}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
              >
                <span style={keyStyle}>تاريخ الإنشاء:</span> {selectedPermission.created_at}
              </Typography>
            </div>
          )}
          {currentCertificate && (
            <div>
              <iframe
                src={`https://backendforerpsystem.zbooma.com/${currentCertificate}`}
                title="Certificate Preview"
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            style={{ fontFamily: "Cairo, sans-serif" }}
          >
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Function to get the appropriate skill icon based on the skill name
const getSkillIcon = (skillName) => {
  switch (skillName) {
    case "WordPress":
      return <WebIcon />;
    case "Graphic Design":
      return <BrushIcon />;
    case "React":
      return <DeveloperModeIcon />;
    case "Node.js":
      return <CodeIcon />;
    case "PHP":
      return <StorageIcon />;
    case "SALLA":
      return <WebIcon />;
    default:
      return null;
  }
};

// Function to get the appropriate professional skill icon based on the skill name
const getProfessionalSkillIcon = (skillName) => {
  switch (skillName) {
    case "حضوره":
      return <EmojiPeopleIcon />;
    case "التزام الوقت":
      return <AccessTimeIcon />;
    case "التعاون":
      return <ThumbUpIcon />;
    case "قيادة الفريق":
      return <LeadershipIcon />;
    case "التواصل":
      return <CommunicationIcon />;
    case "الإبداع":
      return <CreativityIcon />;
    default:
      return null;
  }
};

export default EmployeeDetail;
