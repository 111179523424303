import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Typography, Box, TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, OutlinedInput, Chip, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const TaskList = () => {
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskDueDate, setTaskDueDate] = useState('');
  const [taskDepartments, setTaskDepartments] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const location = useLocation();
  const username = new URLSearchParams(location.search).get('username');

  useEffect(() => {
    axios.get('https://backendforerpsystem.zbooma.com/getDepartments.php')
      .then(response => {
        if (response.data.success) {
          setDepartments(response.data.departments);
        }
      })
      .catch(error => {
        console.error('Error fetching departments:', error);
      });

    axios.get('https://backendforerpsystem.zbooma.com/getemployees.php')
      .then(response => {
        if (response.data.status === 'success') {
          const foundUser = response.data.data.find(emp => emp.username === username);
          setUser(foundUser);
        }
      })
      .catch(error => {
        console.error('Error fetching employees:', error);
      });

    axios.get('https://backendforerpsystem.zbooma.com/get_tasks.php')
      .then(response => {
        if (response.data.success) {
          setTasks(response.data.tasks);
        }
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  }, [username]);

  const handleAddTask = () => {
    if (!taskTitle || !taskDescription || !taskDueDate || taskDepartments.length === 0) {
      Swal.fire('خطأ', 'يرجى ملء جميع الحقول', 'error');
      return;
    }

    const newTask = {
      taskTitle,
      taskDescription,
      taskDueDate,
      taskDepartments,
      username,
    };

    setLoading(true);
    axios.post('https://backendforerpsystem.zbooma.com/add_task.php', newTask)
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire('نجاح', 'تم إضافة المهمة بنجاح!', 'success');
          setTasks([...tasks, newTask]);
          setTaskTitle('');
          setTaskDescription('');
          setTaskDueDate('');
          setTaskDepartments([]);
        } else {
          Swal.fire('خطأ', response.data.message, 'error');
        }
      })
      .catch(error => {
        setLoading(false);
        Swal.fire('خطأ', 'حدث خطأ أثناء إضافة المهمة. حاول مرة أخرى.', 'error');
      });
  };

  const handleDeleteDepartment = (departmentToDelete) => {
    setTaskDepartments(prevDepartments => prevDepartments.filter(department => department !== departmentToDelete));
  };

  if (!user) {
    return <CircularProgress />;
  }

  if (user.position !== 'قائد فريق' && user.position !== 'رئيس' && user.position !== 'مدير عام') {
    return null; // Return nothing if the user is not in a position to view the TaskList
  }

  return (
    <Box p={3} sx={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
      <Typography style={{ fontFamily: 'Cairo' }} variant="h4" gutterBottom>
        قائمة المهام
      </Typography>
      <Typography style={{ fontFamily: 'Cairo' }} variant="body1">
        هذه هي صفحة قائمة المهام. يمكنك عرض جميع مهامك هنا.
      </Typography>
      <Box mt={4}>
        <Typography style={{ fontFamily: 'Cairo' }} variant="h6" gutterBottom>
          إضافة مهمة جديدة
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="عنوان المهمة"
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              variant="outlined"
              inputProps={{ style: { fontFamily: 'Cairo' } }}
              InputLabelProps={{ style: { fontFamily: 'Cairo' } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="تاريخ الاستحقاق"
              type="date"
              value={taskDueDate}
              onChange={(e) => setTaskDueDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
                style: { fontFamily: 'Cairo' }
              }}
              inputProps={{ style: { fontFamily: 'Cairo' } }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="وصف المهمة"
              value={taskDescription}
              onChange={(e) => setTaskDescription(e.target.value)}
              multiline
              rows={4}
              variant="outlined"
              inputProps={{ style: { fontFamily: 'Cairo' } }}
              InputLabelProps={{ style: { fontFamily: 'Cairo' } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel style={{ fontFamily: 'Cairo' }}>الأقسام المعنية</InputLabel>
              <Select
                multiple
                value={taskDepartments}
                onChange={(e) => setTaskDepartments(e.target.value)}
                input={<OutlinedInput label="الأقسام المعنية" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{ fontFamily: 'Cairo' }}
                        deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                        onDelete={() => handleDeleteDepartment(value)}
                      />
                    ))}
                  </Box>
                )}
                sx={{ fontFamily: 'Cairo' }}
              >
                {departments.map((department) => (
                  <MenuItem key={department.id} value={department.name} sx={{ fontFamily: 'Cairo' }}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTask}
            sx={{ fontFamily: 'Cairo' }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'إضافة مهمة'}
          </Button>
        </Box>
      </Box>
      <Box mt={4}>
        <Typography style={{ fontFamily: 'Cairo' }} variant="h6" gutterBottom>
          قائمة المهام المضافة
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="task table">
            <TableHead>
              <TableRow>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>عنوان المهمة</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>وصف المهمة</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>تاريخ الاستحقاق</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>الأقسام المعنية</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>اسم المستخدم</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_title}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_description}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_due_date}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_departments}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.username}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TaskList;
