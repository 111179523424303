import React, { useState } from "react";
import { Box, Button, Typography, Grid, Card, CardMedia, CardContent } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite"; // Play button icon
import { motion } from "framer-motion"; // Importing motion for animations
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'; // Emoji icon for the dialog
import img1 from "../images/ppc-agencey-1-BQMpewIl.png";
import img2 from "../images/ppc-agencey-2-63fz3DEa.png";
import img3 from "../images/ppc-agencey-3-DflRt4Tr.png";
import img4 from "../images/ppc-agencey-4-CPOorMHP.png";

// Continuous text floating animation (adjusted for smoother transition)
const continuousTextVariant = {
  animate: {
    y: [0, -10, 0], // Floating effect up and down
    transition: {
      duration: 3, // Slower movement
      ease: "easeInOut",
      repeat: Infinity, // Repeat forever for continuous animation
    },
  },
};

// Image rotation animation with hover stop
const imageRotation = {
  initial: { rotateY: 0 },
  animate: { rotateY: 360, transition: { duration: 6, ease: "linear", repeat: Infinity } },
  hover: { rotateY: 0, transition: { duration: 0.3 } }, // Stop rotation on hover
};

// Define hover animation for scaling the cards
const cardHoverVariant = {
  initial: { scale: 1 },
  hover: {
    scale: 1.05, // Scale up on hover
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

const PPCSection = () => {
  const [hoveredImage, setHoveredImage] = useState(null); // To track the hovered image

  return (
    <div>
      {/* Styled horizontal rule (hr) */}
      <hr style={{ border: "1px solid #D4FF00", backgroundColor: "transparent", margin: "0 20px" }} />
      <Box
        sx={{
          backgroundColor: "#0d0f10", // Set the background color
          padding: "40px", // Add padding around the section
          display: "flex",
          justifyContent: "center", // Center the content horizontally
        }}
      >
        {/* Main Grid Layout */}
        <Grid container spacing={4} sx={{ maxWidth: "1200px", alignItems: "center" }}>
          {/* Left Section with Heading, Text, and Button */}
          <Grid 
            item xs={12} md={6}
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}
          >
            {/* Animated Title with Floating Effect */}
            <motion.div animate="animate" variants={continuousTextVariant}>
              <Typography
                variant="h3"
                sx={{
                  color: "#D4FF00",
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Best PPC Agency in Europe: <span style={{ textDecoration: "underline" }}>Winner</span>
              </Typography>
            </motion.div>

            {/* Animated Description with Floating Effect */}
            <motion.div animate="animate" variants={continuousTextVariant}>
              <Typography
                variant="body1"
                sx={{
                  color: "#c7d9f9",
                  mb: 4,
                }}
              >
                Paid search marketing is the quickest and most controllable way to get your business in front of people who
                are looking for your products or services. Because you only pay for qualified traffic, it is easy to control.
              </Typography>
            </motion.div>

            {/* Animated Play Now Button */}
            <motion.div animate="animate" variants={continuousTextVariant}>
              <Button
                variant="contained"
                startIcon={<PlayCircleFilledWhiteIcon />}
                sx={{
                  backgroundColor: "#D4FF00",
                  color: "#000",
                  padding: "10px 20px",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#C5F200",
                  },
                }}
              >
                Play Now
              </Button>
            </motion.div>
          </Grid>

          {/* Right Section with Image Cards */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {[img1, img2, img3, img4].map((image, index) => (
                <Grid item xs={6} key={index}>
                  <Card
                    component={motion.div}
                    initial="initial"
                    whileHover="hover"
                    variants={cardHoverVariant}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                      cursor:"pointer",
                      position: "relative", // For dialog positioning
                    }}
                    onMouseEnter={() => setHoveredImage(index)}
                    onMouseLeave={() => setHoveredImage(null)}
                  >
                    <motion.div
                      initial="initial"
                      animate={hoveredImage === index ? "hover" : "animate"}
                      variants={imageRotation} // Apply rotation to the image only
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={image}
                        alt={`Card image ${index + 1}`}
                      />
                    </motion.div>
                    <CardContent>
                      <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
                        {index === 0 ? "SEO Strategy" : index === 1 ? "SEO Analysis" : index === 2 ? "Optimization" : "Web & Mobile"}
                      </Typography>
                    </CardContent>

                    {/* Show dialog when hovered */}
                    {hoveredImage === index && (
                    <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5, ease: "easeOut" }} // Smooth drop animation
                    style={{
                      position: "absolute",
                      top: "20px",
                      transform: "translateX(-50%)",
                      backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent background with a slight tint
                      padding: "15px 20px",
                      borderRadius: "10px",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      backdropFilter: "blur(10px)", // Adds a blur effect behind the dialog
                      border: "1px solid rgba(255, 255, 255, 0.4)", // Slight border for definition
                    }}
                  >
                    <EmojiEmotionsIcon sx={{ color: "white", fontSize: "2rem" }} />
                    <Typography variant="body2" sx={{ color: "#fff", fontWeight: "bold" }}>
                      oooooh you stopped me, please leave me to work!
                    </Typography>
                  </motion.div>
                  
                    
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PPCSection;
