import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import WebIcon from "@mui/icons-material/Web";
import BrushIcon from "@mui/icons-material/Brush";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import CodeIcon from "@mui/icons-material/Code";
import StorageIcon from "@mui/icons-material/Storage";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LeadershipIcon from "@mui/icons-material/EmojiEvents";
import CommunicationIcon from "@mui/icons-material/Forum";
import CreativityIcon from "@mui/icons-material/Lightbulb";
import { useLocation } from "react-router-dom";

// Styled components for progress bar animation
const AnimatedProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20, // Thicker sliders
  borderRadius: 5,
  backgroundColor: "#e0e0e0",
  overflow: "hidden",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#6fd943", // Slider color
    animation: "1.5s forwards ease-in-out",
  },
}));

// Styled component for skill icons with hover animation
const SkillIcon = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

// Hook to extract query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TechnicalSkills = () => {
  const [skills, setSkills] = useState([]);
  const [disciplineSkills, setDisciplineSkills] = useState([]); // New state for discipline skills
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const username = query.get("username");
        const employeesResponse = await fetch(
          "https://backendforerpsystem.zbooma.com/getemployees.php"
        );
        if (!employeesResponse.ok) {
          throw new Error("Network response was not ok");
        }
        const employeesResult = await employeesResponse.json();
  
        const employee = employeesResult.data.find(
          (emp) => emp.username === username
        );
  
        if (employee) {
          const skillsResponse = await fetch(
            `https://backendforerpsystem.zbooma.com/getSkills.php?employeeId=${employee.employee_id}`
          );
          if (!skillsResponse.ok) {
            throw new Error("Network response was not ok");
          }
          const skillsResult = await skillsResponse.json();
  
          const disciplineSkillsResponse = await fetch(
            `https://backendforerpsystem.zbooma.com/getDisciplineSkills.php?employeeId=${employee.employee_id}`
          );
          if (!disciplineSkillsResponse.ok) {
            throw new Error("Network response was not ok");
          }
          const disciplineSkillsResult = await disciplineSkillsResponse.json();
  
          if (skillsResult.success) {
            setSkills(skillsResult.skills);
          } else {
            setError("Failed to fetch skills: " + skillsResult.message);
          }
  
          if (disciplineSkillsResult.success) {
            setDisciplineSkills(disciplineSkillsResult.skills);
          } else {
            setError(
              "Failed to fetch discipline skills: " +
                disciplineSkillsResult.message
            );
          }
        } else {
          setError("No employee found with username: " + username);
        }
      } catch (err) {
        setError("Error fetching data: " + err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchSkills();
  }, [query]);
  

  if (loading) {
    return (
      <Box style={{ padding: "24px", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box style={{ padding: "24px", textAlign: "center" }}>
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box style={{ padding: "24px", direction: "rtl" }}>
      <Typography
        variant="h6"
        style={{
          color: "#6fd943",
          fontFamily: "Cairo, sans-serif",
          textAlign: "right",
          marginBottom: "16px",
        }}
      >
        المهارات التقنية
      </Typography>
      <Grid container spacing={2}>
        {skills.map((skill, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Tooltip title={`${skill.skill_name} proficiency level`} arrow>
              <Box display="flex" alignItems="center">
                <SkillIcon style={{ cursor: "pointer" }}>
                  {getSkillIcon(skill.skill_name)}
                </SkillIcon>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "Cairo, sans-serif",
                    marginBottom: "4px",
                    color: "gray",
                    width: "120px",
                    flexShrink: 0,
                  }}
                >
                  {skill.skill_name}
                </Typography>
                <Box flexGrow={1} mx={2}>
                  <AnimatedProgress
                    variant="determinate"
                    value={skill.percentage}
                  />
                </Box>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Cairo, sans-serif",
                    color: "gray",
                    marginTop: "4px",
                    flexShrink: 0,
                  }}
                >
                  {skill.percentage}%
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>

      <Typography
        variant="h6"
        style={{
          color: "#6fd943",
          fontFamily: "Cairo, sans-serif",
          textAlign: "right",
          marginTop: "24px",
          marginBottom: "16px",
        }}
      >
        المهارات الشخصية والانضباطية
      </Typography>
      <Grid container spacing={2}>
        {disciplineSkills.map((skill, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Tooltip title={`${skill.skill_name} proficiency level`} arrow>
              <Box display="flex" alignItems="center">
                <SkillIcon style={{ cursor: "pointer" }}>
                  {getProfessionalSkillIcon(skill.skill_name)}
                </SkillIcon>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "Cairo, sans-serif",
                    marginBottom: "4px",
                    color: "gray",
                    width: "120px",
                    flexShrink: 0,
                  }}
                >
                  {skill.skill_name}
                </Typography>
                <Box flexGrow={1} mx={2}>
                  <AnimatedProgress
                    variant="determinate"
                    value={skill.percentage}
                  />
                </Box>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Cairo, sans-serif",
                    color: "gray",
                    marginTop: "4px",
                    flexShrink: 0,
                  }}
                >
                  {skill.percentage ? `${skill.percentage}%` : "لا توجد قيمة"}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Function to get the appropriate skill icon based on the skill name
const getSkillIcon = (skillName) => {
  switch (skillName) {
    case "WordPress":
      return <WebIcon />;
    case "Graphic Design":
      return <BrushIcon />;
    case "React":
      return <DeveloperModeIcon />;
    case "Node.js":
      return <CodeIcon />;
    case "PHP":
      return <StorageIcon />;
    case "SALLA":
      return <WebIcon />;
    default:
      return null;
  }
};

// Function to get the appropriate professional skill icon based on the skill name
const getProfessionalSkillIcon = (skillName) => {
  switch (skillName) {
    case "حضوره":
      return <EmojiPeopleIcon />;
    case "التزام الوقت":
      return <AccessTimeIcon />;
    case "التعاون":
      return <ThumbUpIcon />;
    case "قيادة الفريق":
      return <LeadershipIcon />;
    case "التواصل":
      return <CommunicationIcon />;
    case "الإبداع":
      return <CreativityIcon />;
    default:
      return null;
  }
};

export default TechnicalSkills;
