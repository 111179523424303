import React, { useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion"; // Import motion from Framer Motion
import image from "../images/banner.png"; // Import the image file
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'; // Import an emoji icon for the funny effect

// Helper function to generate motion.span for each letter, including spaces
const AnimatedLetters = ({ text, delay = 0, variant = "h2", color = "#ccdeff"}) => {
  const letters = Array.from(text); // Convert string to array of characters

  const letterVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.05 + delay, // Stagger animation for each letter
        duration: 0.5,
      },
    }),
  };

  return (
    <Typography
      variant={variant} // Use the passed variant for different text sizes
      component="div" // Ensures each sentence is in its own block
      sx={{
        fontWeight: variant === "h2" ? "bold" : "normal",
        fontSize: variant === "h2" ? { xs: "2rem", md: "3.5rem" } : { xs: "1rem", md: "1.25rem" },
        marginBottom: "20px",
        color: color,
      }}
    >
      {letters.map((letter, i) => (
        <motion.span
          key={i}
          custom={i}
          initial="hidden"
          animate="visible"
          variants={letterVariant}
          style={{ display: "inline-block" }}
        >
          {letter === " " ? "\u00A0" : letter} {/* Handle spaces properly */}
        </motion.span>
      ))}
    </Typography>
  );
};

const BannerComponent = () => {
  const [isHovered, setIsHovered] = useState(false); // State to track hover

  const buttonVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 1.5 } },
  };

  const imageVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1, delay: 1.7 } },
  };

  // New swim effect using keyframes-like motion
  const swimEffect = {
    animate: {
      x: [0, 10, -10, 0], // Move horizontally in a wave motion
      y: [0, -5, 5, 0], // Move vertically to simulate swimming
    },
    transition: {
      duration: 4, // Full cycle time for the swim effect
      ease: "easeInOut",
      repeat: Infinity, // Keep repeating the animation
      repeatType: "mirror", // Reflect the movement back and forth
    },
  };

  // Dialog animation for hover
  const dialogVariant = {
    hidden: { opacity: 0, y: -100 }, // Start above the image
    visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: "easeOut" } }, // Drop to the top of the image
  };

  return (
    <Box
      sx={{
        backgroundColor: "#0d0f10", // Background color for the entire content
        padding: "40px", // Adjust padding as needed
      }}
    >
      {/* First Section: Title, Subtitle, and Button */}
      <Box
        sx={{
          padding: { xs: "40px 20px", md: "80px 40px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#fff",
          width: "90%",
          margin: "auto",
        }}
      >
        <Grid container spacing={4} alignItems="center">
          {/* Left side with Title */}
          <Grid item xs={12} md={8}>
            {/* First Part of the Text - "A Fully Integrated Digital" */}
            <AnimatedLetters text="put technology at your service" fontSize="1rem" />

            {/* Second Part of the Text - "Marketing Agency" */}
            <AnimatedLetters text="Leading IT company" delay={1.5} />
          </Grid>

          {/* Right side with Subtitle and Button */}
          <Grid item xs={12} md={4} sx={{ textAlign: "left" }}>
            {/* Animated subtitle text */}
            <AnimatedLetters
              text="We are a leading IT company. We guaranteee you a high level of service and quality.."
              delay={2.5} // Delay to show after the main title
              variant="h6" // Set variant to h6 for smaller text size
              color="#c7d9f9" // Customize the color for the subtitle
            />

            {/* Call-to-Action Button */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={buttonVariant} // Apply animation to button
            >
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  backgroundColor: "#D4FF00",
                  color: "#000",
                  textAlign: "left",
                  padding: "10px 20px",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#C5F200",
                  },
                }}
              >
                Get Started
              </Button>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      {/* Second Section: Image Banner with Swimming Effect */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Ensure the container centers the image
          alignItems: "center",
          padding: "20px",
          marginTop: "20px", // Space between the two sections
          position: "relative", // Set relative position for absolute dialog
        }}
        onMouseEnter={() => setIsHovered(true)} // Show dialog on hover
        onMouseLeave={() => setIsHovered(false)} // Hide dialog on hover out
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={imageVariant} // Apply fade-in animation to image
          style={{ width: "90%", display: "flex", justifyContent: "center" }} // Center image container
        >
          <motion.div
            animate={swimEffect.animate}
            transition={swimEffect.transition}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              src={image} // Using the imported image directly here
              alt="Banner"
              style={{
                width: "100%", // Make sure image takes up 100% of the parent width
                height: "auto",
                borderRadius: "15px",
              }}
            />
          </motion.div>
        </motion.div>

        {/* Dialog Box on Hover */}
        {isHovered && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={dialogVariant} // Apply the drop-down animation
            style={{
              position: "absolute",
              top: "-80px", // Start above the image
              left: "50%",
              transform: "translateX(-50%)", // Center the dialog horizontally
              backgroundColor: "#fff",
              padding: "10px 20px",
              borderRadius: "15px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {/* <EmojiEmotionsIcon sx={{ fontSize: "2rem", color: "#0d0f10" }} /> */}
            Hello there, we know that you will hover the image! 😄
            <br/>
            Just enjoy this page, it's full of fun!
          </motion.div>
        )}
      </Box>
    </Box>
  );
};

export default BannerComponent;
