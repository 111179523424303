import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Card, CardContent, CardActions, Button, Grid,
  CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, FormControl, InputLabel, Avatar, Tooltip, IconButton
} from '@mui/material';
import { CheckCircle, HourglassEmpty, HourglassFull, Delete } from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const TaskView = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [passDialogOpen, setPassDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [currentMonth, setCurrentMonth] = useState(dayjs().month());

  const location = useLocation();
  const username = new URLSearchParams(location.search).get('username');

  useEffect(() => {
    axios.get('https://backendforerpsystem.zbooma.com/getemployees.php')
      .then(response => {
        if (response.data.status === 'success') {
          setEmployees(response.data.data);
          const foundUser = response.data.data.find(emp => emp.username === username);
          setUser(foundUser);
        }
      })
      .catch(error => {
        console.error('Error fetching user:', error);
      });

    fetchTasks();
  }, [username]);

  const fetchTasks = () => {
    axios.get('https://backendforerpsystem.zbooma.com/get_tasks.php')
      .then(response => {
        if (response.data.success) {
          const tasksWithDefaultStatus = response.data.tasks.map(task => ({
            ...task,
            status: task.status || 'pending',
            updated_by: task.updated_by || null,
            passed_to: task.passed_to || null
          }));
          setTasks(tasksWithDefaultStatus);
        }
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user && !['قائد فريق', 'رئيس', 'مدير عام'].includes(user.position)) {
      axios.post('https://backendforerpsystem.zbooma.com/get_employee_tasks.php', {
        username: user.username
      })
        .then(response => {
          if (response.data.success) {
            setTasks(response.data.tasks);
          } else {
            console.error('Error fetching employee tasks:', response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching employee tasks:', error);
        });
    }
  }, [user]);

  const handleOpenDialog = (task) => {
    setSelectedTask(task);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedTask(null);
    setDialogOpen(false);
  };

  const handleOpenPassDialog = (task) => {
    setSelectedTask(task);
    setPassDialogOpen(true);
  };

  const handleClosePassDialog = () => {
    setSelectedTask(null);
    setPassDialogOpen(false);
  };

  const handleStatusChange = (taskId, newStatus) => {
    axios.post('https://backendforerpsystem.zbooma.com/update_task_status.php', {
      taskId,
      newStatus,
      username: user.username
    })
      .then(response => {
        if (response.data.success) {
          setTasks(prevTasks =>
            prevTasks.map(task =>
              task.id === taskId ? { ...task, status: newStatus, updated_by: user.username } : task
            )
          );
        } else {
          console.error('Error updating task status:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error updating task status:', error);
      });
  };

  const handlePassTask = () => {
    axios
      .post('https://backendforerpsystem.zbooma.com/pass_task.php', {
        taskId: selectedTask.id,
        newAssignee: selectedEmployee
      })
      .then(response => {
        if (response.data.success) {
          setTasks(prevTasks =>
            prevTasks.map(task =>
              task.id === selectedTask.id
                ? { ...task, username: selectedEmployee, passed: 1, passed_to: selectedEmployee }
                : task
            )
          );
          handleClosePassDialog();
          Swal.fire('نجاح', 'تم تمرير المهمة بنجاح!', 'success');
        } else {
          console.error('Error passing task:', response.data.message);
          Swal.fire('خطأ', response.data.message, 'error');
        }
      })
      .catch(error => {
        console.error('Error passing task:', error);
        Swal.fire('خطأ', 'حدث خطأ أثناء تمرير المهمة.', 'error');
      });
  };

  const handleDeleteTask = (taskId) => {
    Swal.fire({
      title: 'هل أنت متأكد?',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم، احذفها!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('https://backendforerpsystem.zbooma.com/delete_task.php', { taskId })
          .then(response => {
            if (response.data.success) {
              setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
              Swal.fire('تم الحذف!', 'تم حذف المهمة بنجاح.', 'success');
            } else {
              console.error('Error deleting task:', response.data.message);
              Swal.fire("خطأ", response.data.message, "error");
            }
          })
          .catch(error => {
            console.error('Error deleting task:', error);
            Swal.fire("خطأ", "حدث خطأ أثناء حذف المهمة.", "error");
          });
      }
    });
  };

  const handleDeleteAllTasks = () => {
    Swal.fire({
      title: 'هل أنت متأكد?',
      text: "سيتم حذف جميع المهام لهذا الشهر",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم، احذفها!'
    }).then((result) => {
      if (result.isConfirmed) {
        const taskIdsToDelete = filteredTasks.map(task => task.id);
        axios.post('https://backendforerpsystem.zbooma.com/delete_tasks.php', { taskIds: taskIdsToDelete })
          .then(response => {
            if (response.data.success) {
              setTasks(prevTasks => prevTasks.filter(task => !taskIdsToDelete.includes(task.id)));
              Swal.fire('تم الحذف!', 'تم حذف جميع المهام لهذا الشهر بنجاح.', 'success');
            } else {
              console.error('Error deleting tasks:', response.data.message);
              Swal.fire("خطأ", response.data.message, "error");
            }
          })
          .catch(error => {
            console.error('Error deleting tasks:', error);
            Swal.fire("خطأ", "حدث خطأ أثناء حذف المهام.", "error");
          });
      }
    });
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'done':
        return <CheckCircle style={{ color: 'green' }} />;
      case 'processing':
        return <HourglassFull style={{ color: 'orange' }} />;
      case 'pending':
        return <HourglassEmpty style={{ color: 'gray' }} />;
      default:
        return null;
    }
  };

  const getEmployeeByUsername = (username) => {
    return employees.find(emp => emp.username === username);
  };

  const handleMonthChange = (direction) => {
    setCurrentMonth(prevMonth => {
      const newMonth = direction === 'next' ? prevMonth + 1 : prevMonth - 1;
      return newMonth < 0 ? 11 : newMonth > 11 ? 0 : newMonth;
    });
  };

  const filteredTasks = tasks.filter(task => {
    const taskDepartments = task.task_departments.split(',').map(department => department.trim());
    const taskMonth = dayjs(task.task_due_date).month();
    return taskDepartments.includes(user?.department2) && taskMonth === currentMonth;
  });

  const filteredEmployees = employees.filter(emp => emp.department2 === user?.department2 && emp.position === 'موظف');

  if (loading || !user) {
    return <CircularProgress />;
  }

  return (
    <Box p={3} sx={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'Cairo, sans-serif' }}>
        عرض المهام
      </Typography>
      <Typography variant="body1" style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '20px' }}>
        هذه هي صفحة عرض المهام. يمكنك عرض معلومات مفصلة عن مهامك هنا.
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button variant="contained" onClick={() => handleMonthChange('previous')} sx={{ fontFamily: 'Cairo, sans-serif' }}>
          الشهر السابق
        </Button>
        <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif' }}>
          {dayjs().month(currentMonth).format('MMMM')}
        </Typography>
        <Button variant="contained" onClick={() => handleMonthChange('next')} sx={{ fontFamily: 'Cairo, sans-serif' }}>
          الشهر التالي
        </Button>
      </Box>
      <Grid container spacing={3}>
        {filteredTasks.length === 0 && (
          <Box p={3} sx={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
            <Typography variant="h6" style={{ fontFamily: 'Cairo, sans-serif' }}>
              لا توجد مهام لعرضها في هذا الشهر.
            </Typography>
          </Box>
        )}
        {filteredTasks.map((task, index) => {
          const employee = getEmployeeByUsername(task.username);
          const updatedByEmployee = getEmployeeByUsername(task.updated_by);
          const passedToEmployee = getEmployeeByUsername(task.passed_to);
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ minHeight: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom style={{ fontFamily: 'Cairo, sans-serif', fontWeight: 'bold' }}>
                    {task.task_title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '10px' }}>
                    {task.task_description.length > 50 ? `${task.task_description.substring(0, 50)}...` : task.task_description}
                  </Typography>
                  <FormControl fullWidth variant="outlined" sx={{ marginTop: '10px' }}>
                    <InputLabel style={{ fontFamily: 'Cairo, sans-serif' }}>حالة المهمة</InputLabel>
                    <Select
                      value={task.status || 'pending'}
                      onChange={(e) => handleStatusChange(task.id, e.target.value)}
                      label="حالة المهمة"
                      sx={{ fontFamily: 'Cairo, sans-serif' }}
                    >
                      <MenuItem value="pending" sx={{ fontFamily: 'Cairo, sans-serif' }}>معلقة</MenuItem>
                      <MenuItem value="processing" sx={{ fontFamily: 'Cairo, sans-serif' }}>قيد التنفيذ</MenuItem>
                      <MenuItem value="done" sx={{ fontFamily: 'Cairo, sans-serif' }}>مكتملة</MenuItem>
                    </Select>
                  </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                  <Box display="flex" alignItems="center">
                    {getStatusIcon(task.status)}
                    {(task.status === 'processing' || task.status === 'done') && (
                      <>
                        <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif', marginRight: '8px' }}>
                          {task.status === 'processing' ? 'يتم العمل عليه بواسطة' : 'تم العمل عليه بواسطة'}
                        </Typography>
                        <Tooltip title={
                          <Box>
                            <Typography variant="body2"><strong style={{ textAlign: "right" }}>اسم:</strong> {updatedByEmployee?.name}</Typography>
                            <Typography variant="body2"><strong>البريد الإلكتروني:</strong> {updatedByEmployee?.email}</Typography>
                            <Typography variant="body2"><strong>الهاتف:</strong> {updatedByEmployee?.phone}</Typography>
                            <Typography variant="body2"><strong>القسم:</strong> {updatedByEmployee?.department}</Typography>
                            <Typography variant="body2"><strong>المنصب:</strong> {updatedByEmployee?.position}</Typography>
                          </Box>
                        }>
                          <Avatar alt={updatedByEmployee?.name} src={`https://backendforerpsystem.zbooma.com/${updatedByEmployee?.photo}`} />
                        </Tooltip>
                      </>
                    )}
                  </Box>
                  <Button size="small" color="primary" sx={{ fontFamily: 'Cairo, sans-serif' }} onClick={() => handleOpenDialog(task)}>
                    عرض التفاصيل
                  </Button>
                  {['قائد فريق', 'رئيس', 'مدير'].includes(user?.position) && !task.passed && (
                    <Button size="small" color="secondary" sx={{ fontFamily: 'Cairo, sans-serif' }} onClick={() => handleOpenPassDialog(task)}>
                      تمرير
                    </Button>
                  )}
                  {['قائد فريق', 'رئيس', 'مدير'].includes(user?.position) && (
                    <IconButton color="error" onClick={() => handleDeleteTask(task.id)}>
                      <Delete />
                    </IconButton>
                  )}
                </CardActions>
                {task.passed_to && passedToEmployee && (
                  <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px">
                    <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center', marginRight: '8px' }}>
                      تم التمرير إلى
                    </Typography>
                    <Tooltip title={
                      <Box>
                        <Typography variant="body2"><strong>اسم:</strong> {passedToEmployee.name}</Typography>
                        <Typography variant="body2"><strong>البريد الإلكتروني:</strong> {passedToEmployee.email}</Typography>
                        <Typography variant="body2"><strong>الهاتف:</strong> {passedToEmployee.phone}</Typography>
                        <Typography variant="body2"><strong>القسم:</strong> {passedToEmployee.department}</Typography>
                        <Typography variant="body2"><strong>المنصب:</strong> {passedToEmployee.position}</Typography>
                      </Box>
                    }>
                      <Avatar alt={passedToEmployee.name} src={`https://backendforerpsystem.zbooma.com/${passedToEmployee.photo}`} />
                    </Tooltip>
                  </Box>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {selectedTask && (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle style={{ fontFamily: 'Cairo, sans-serif' }}>تفاصيل المهمة</DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'Cairo, sans-serif', fontWeight: 'bold' }}>
              {selectedTask.task_title}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif' }}>
              <strong>وصف المهمة:</strong> {selectedTask.task_description}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif' }}>
              <strong>تاريخ الاستحقاق:</strong> {selectedTask.task_due_date}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif' }}>
              <strong>الأقسام المعنية:</strong> {selectedTask.task_departments}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif' }}>
              <strong>اسم المستخدم:</strong> {selectedTask.username}
            </Typography>
            <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif' }}>
              <strong>حالة المهمة:</strong> {selectedTask.status || 'pending'}
            </Typography>
            {(selectedTask.status === 'processing' || selectedTask.status === 'done') && (
              <>
                <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>تم العمل عليه بواسطة:</strong> {getEmployeeByUsername(selectedTask.updated_by)?.name}
                </Typography>
                <Tooltip title={
                  <Box>
                    <Typography variant="body2"><strong>اسم:</strong> {getEmployeeByUsername(selectedTask.updated_by)?.name}</Typography>
                    <Typography variant="body2"><strong>البريد الإلكتروني:</strong> {getEmployeeByUsername(selectedTask.updated_by)?.email}</Typography>
                    <Typography variant="body2"><strong>الهاتف:</strong> {getEmployeeByUsername(selectedTask.updated_by)?.phone}</Typography>
                    <Typography variant="body2"><strong>القسم:</strong> {getEmployeeByUsername(selectedTask.updated_by)?.department}</Typography>
                    <Typography variant="body2"><strong>المنصب:</strong> {getEmployeeByUsername(selectedTask.updated_by)?.position}</Typography>
                  </Box>
                }>
                  <Avatar alt={getEmployeeByUsername(selectedTask.updated_by)?.name} src={`https://backendforerpsystem.zbooma.com/${getEmployeeByUsername(selectedTask.updated_by)?.photo}`} />
                </Tooltip>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary" sx={{ fontFamily: 'Cairo, sans-serif' }}>
              إغلاق
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {selectedTask && (
        <Dialog open={passDialogOpen} onClose={handleClosePassDialog}>
          <DialogTitle style={{ fontFamily: 'Cairo, sans-serif' }}>تمرير المهمة</DialogTitle>
          <DialogContent>
            <Typography variant="body2" style={{ fontFamily: 'Cairo, sans-serif', marginBottom: '10px' }}>
              اختر الموظف لتمرير المهمة:
            </Typography>
            <Select
              fullWidth
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
              label="اختر الموظف"
              sx={{ fontFamily: 'Cairo, sans-serif' }}
            >
              {filteredEmployees.map((employee) => (
                <MenuItem key={employee.username} value={employee.username} sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  {employee.name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePassDialog} color="primary" sx={{ fontFamily: 'Cairo, sans-serif' }}>
              إغلاق
            </Button>
            <Button onClick={handlePassTask} color="secondary" sx={{ fontFamily: 'Cairo, sans-serif' }}>
              تمرير
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete all tasks button for specific roles */}
      {['قائد فريق', 'رئيس', 'مدير'].includes(user?.position) && (
        <Box mt={3} textAlign="center">
          <Button variant="contained" color="error" onClick={handleDeleteAllTasks} sx={{ fontFamily: 'Cairo, sans-serif' }}>
            حذف جميع المهام لهذا الشهر
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TaskView;
