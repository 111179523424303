import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box, Button, Divider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import image from "../../images/logo-white.png"
import BannerComponent from "../../Components/BannerComponent";
import ServicesSection from "../../Components/ServicesSectionNew";
import PPCSection from "../../Components/PPCSection";
import { useNavigate } from "react-router-dom";
// Create RTL Cache for MUI
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create a theme with RTL direction and Cairo font
const theme = createTheme({
  direction: 'rtl', // Enable RTL direction
  typography: {
    fontFamily: '"Cairo", sans-serif', // Set Cairo font family globally
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: 0, // Remove padding
          margin: 0,  // Remove margin
        }
      }
    }
  }
});


const PublicHome = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    navigate('/Login')

  };
  const handleMenuOpenTake = ()=>{
    navigate('/')
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static" style={{ backgroundColor: "black", padding: 0, margin: 0,border: "2px dashed white"}}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            
            {/* Logo Section */}
            <Box display="flex" alignItems="center">
              <img
                src={image}
                alt="logo"
                style={{ width: "180px", marginRight: "10px" }}
              />
            </Box>

            {/* Divider between logo and menu */}
            <Divider orientation="vertical" flexItem sx={{ borderColor: 'white', mx: 2 }} />

            {/* Centered Menu Section with Dividers and Spacing */}
            <Box display="flex" flexDirection="row-reverse" flexGrow={1} justifyContent="center" alignItems="center">
              <Button color="inherit" onClick={handleMenuOpenTake} sx={{ mx: 2, fontSize: "1.1rem" }}>
                الصفحة الرئيسية
              </Button>
              
              {/* Divider between menu items */}
              <Divider orientation="vertical" flexItem sx={{ borderColor: 'white', height: '24px', mx: 2 }} />

              <Button color="inherit" onClick={handleMenuOpen} sx={{ mx: 2, fontSize: "1.1rem" }}>
                دخول
              </Button>
            </Box>

            {/* Divider between menu and search icon */}
            <Divider orientation="vertical" flexItem sx={{ borderColor: 'white', mx: 2 }} />

            {/* Right-aligned Section */}
            <Box display="flex" alignItems="center">
              {/* Search Icon */}
              <IconButton color="inherit" sx={{ fontSize: "1.5rem" }}>
                <SearchIcon fontSize="large" />
              </IconButton>

              {/* Divider between search icon and phone number */}

              {/* Contact Information */}
              <Divider orientation="vertical" flexItem sx={{ borderColor: 'white', mx: 2,height:"62px"}} />
              <Typography variant="body1" style={{ marginLeft: "15px", fontSize: "1.1rem" }}>
                +8 (123) 985 789
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </CacheProvider>
    <BannerComponent/>
    <ServicesSection/>
    <PPCSection/>
    </> 
  );
};

export default PublicHome;
