import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Avatar, Grid, Paper, IconButton, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { Doughnut, Line } from 'react-chartjs-2';
import { Description, Flag, Visibility as VisibilityIcon } from '@mui/icons-material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js';
import TechnicalSkills from '../../Components/TechnicalSkills';

ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

const Profile = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get('username');
  const [employee, setEmployee] = useState(null);
  const [completedTasksCount, setCompletedTasksCount] = useState(0);
  const [totalTasksCount, setTotalTasksCount] = useState(0);
  const [monthlyTaskData, setMonthlyTaskData] = useState([]);
  const [countdown, setCountdown] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState(null);
  const [deductions, setDeductions] = useState([]); // New state for deductions

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getemployees.php');
        const result = await response.json();
        if (result.status === 'success') {
          const user = result.data.find(emp => emp.username === username);
          if (user) {
            await fetchEmployeeDetails(user.employee_id);
            fetchAllTasks(user.department2, username);
            startCountdown(user.joining_date);
            fetchAttendanceRecords(user.username); // Fetch attendance records for deductions
          }
        } else {
          console.error('Failed to fetch employees:', result.message);
        }
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    const fetchEmployeeDetails = async (employeeId) => {
      try {
        const response = await fetch(`https://backendforerpsystem.zbooma.com/getemployee.php?id=${employeeId}`);
        const result = await response.json();
        if (result.status === 'success') {
          const employeeData = result.data;
          employeeData.certificates = JSON.parse(employeeData.certificates || '[]');
          setEmployee(employeeData);
        } else {
          console.error('Failed to fetch employee:', result.message);
        }
      } catch (error) {
        console.error('Error fetching employee:', error);
      }
    };

    const fetchAllTasks = async (department, username) => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getalltasksforallemloyees.php');
        const result = await response.json();
        if (result.success) {
          const tasksInDepartment = result.tasks.filter(task =>
            task.task_departments.split(',').map(dep => dep.trim()).includes(department)
          );
          const completedTasks = tasksInDepartment.filter(task => task.username === username && task.status === 'done');
          setCompletedTasksCount(completedTasks.length);
          setTotalTasksCount(tasksInDepartment.length);

          const tasksByMonth = new Array(12).fill(0);
          tasksInDepartment.forEach(task => {
            const taskMonth = new Date(task.task_due_date).getMonth();
            tasksByMonth[taskMonth] += 1;
          });
          setMonthlyTaskData(tasksByMonth);

        } else {
          console.error('Failed to fetch tasks:', result.message);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    const startCountdown = (joiningDate) => {
      const nextAnniversary = new Date(joiningDate);
      nextAnniversary.setFullYear(nextAnniversary.getFullYear() + 1);

      const interval = setInterval(() => {
        const now = new Date();
        const timeLeft = nextAnniversary - now;

        if (timeLeft < 0) {
          nextAnniversary.setFullYear(nextAnniversary.getFullYear() + 1);
        }

        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setCountdown(`${days} يوم ${hours} ساعة ${minutes} دقيقة ${seconds} ثانية`);

        if (timeLeft < 0) {
          clearInterval(interval);
          setCountdown('تم الوصول إلى الزيادة الخاصة بك!');
        }
      }, 1000);
    };

    const fetchAttendanceRecords = async (username) => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/get_attendance_month.php');
        const result = await response.json();
        if (result.success) {
          const userAttendance = result.records.filter(record => record.username === username);
          calculateDeductions(userAttendance);
        } else {
          console.error('Failed to fetch attendance records:', result.message);
        }
      } catch (error) {
        console.error('Error fetching attendance records:', error);
      }
    };

    const calculateDeductions = (records) => {
      if (!employee) return; // Ensure employee data is available

      const calculatedDeductions = [];
      records.forEach((record) => {
        const attendanceTime = new Date(`1970-01-01T${record.time}Z`);
        const thresholdTime1 = new Date(`1970-01-01T09:16:00Z`);
        const thresholdTime2 = new Date(`1970-01-01T09:30:00Z`);

        if (attendanceTime > thresholdTime1 && attendanceTime <= thresholdTime2) {
          const quarterDayDeduction = (employee.salary / 30) / 4;
          calculatedDeductions.push({
            date: record.date,
            time: record.time,
            deduction: quarterDayDeduction.toFixed(2),
            reason: "مخصوم ربع يوم",
          });
        } else if (attendanceTime > thresholdTime2) {
          const halfDayDeduction = (employee.salary / 30) / 2;
          calculatedDeductions.push({
            date: record.date,
            time: record.time,
            deduction: halfDayDeduction.toFixed(2),
            reason: "مخصوم نصف يوم",
          });
        }
      });
      setDeductions(calculatedDeductions);
    };

    fetchEmployeeData();
  }, [username, employee]); // Add employee as a dependency to ensure deductions are calculated after employee data is available

  if (!employee) {
    return <div>Loading...</div>;
  }

  const cardStyle = {
    padding: '24px',
    margin: '16px 0',
    borderRadius: '10px',
    boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
    direction: 'rtl',
    fontFamily: 'Cairo, sans-serif',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    flexGrow: 1,
  };

  const avatarStyle = {
    width: '80px',
    height: '80px',
    marginBottom: '8px',
  };

  const countdownStyle = {
    fontSize: '18px',
    color: '#3f51b5',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '16px',
    border: '2px solid #3f51b5',
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: '#f3f4f6',
    fontFamily: 'Cairo, sans-serif',
  };

  const promotionSectionStyle = {
    backgroundColor: '#e3f2fd',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
    border: '1px solid #90caf9',
  };

  const promotionTextStyle = {
    color: '#1e88e5',
    fontWeight: 'bold',
    fontFamily: 'Cairo',
  };

  const keyStyle = {
    color: '#6fd943',
    fontWeight: 'bold',
    display: 'inline',
  };

  const doughnutData = {
    labels: ['منجز', 'غير مكتمل'],
    datasets: [
      {
        data: [completedTasksCount, totalTasksCount - completedTasksCount],
        backgroundColor: ['#4caf50', '#f44336'],
        hoverBackgroundColor: ['#66bb6a', '#e57373'],
      },
    ],
  };

  const doughnutOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            family: 'Cairo, sans-serif',
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const lineData = {
    labels: [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر'
    ],
    datasets: [
      {
        label: 'عدد المهام المنجزة',
        data: monthlyTaskData,
        fill: false,
        borderColor: '#42a5f5',
        tension: 0.1,
      },
    ],
  };

  const lineOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            family: 'Cairo, sans-serif',
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'الشهور',
          font: {
            family: 'Cairo, sans-serif',
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'عدد المهام',
          font: {
            family: 'Cairo, sans-serif',
            size: 14,
          },
        },
        beginAtZero: true,
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  const roleOrder = ['Junior', 'Mid-Level', 'Senior', 'Team Leader', 'Head'];

  const handleOpenDialog = (certificate) => {
    setCurrentCertificate(certificate);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentCertificate(null);
  };

  const canPreviewFile = (filename) => {
    return filename.endsWith('.pdf') || filename.endsWith('.docx') || filename.endsWith('.ppt') || filename.endsWith('.pptx');
  };

  return (
    <Box p={3} sx={{ direction: 'rtl' }}>
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
        الملف الشخصي
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper style={cardStyle}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar
                alt={employee.name}
                src={`https://backendforerpsystem.zbooma.com/${employee.photo}`}
                style={avatarStyle}
              />
              <Typography
                variant="h6"
                style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', textAlign: 'center', color: '#6fd943' }}
              >
                {employee.name}
              </Typography>
              <Typography
                variant="body1"
                style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}
              >
                <span style={keyStyle}>المنصب:</span> {employee.position}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              تفاصيل الشركة
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>فرع:</span> {employee.branch}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>الادارة:</span> {employee.department}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>القسم:</span> {employee.department2}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>تاريخ الانضمام:</span> {employee.joining_date}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              تفاصيل شخصية
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>كود الموظف:</span> {employee.employee_code}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>الاسم:</span> {employee.name}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>تاريخ الميلاد:</span> {employee.date_of_birth}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>البريد الالكتروني:</span> {employee.email}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>العنوان:</span> {employee.address}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>الجنس:</span> {employee.gender}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>الهاتف:</span> {employee.phone}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>المرتب:</span> {employee.salary} جنيه
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              تفاصيل الحساب المصرفي
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>رقم الحساب:</span> {employee.account_number}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>اسم صاحب الحساب:</span> {employee.account_holder_name}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>اسم البنك:</span> {employee.bank_name}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>كود تعريف البنك:</span> {employee.bank_identifier_code}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              <span style={keyStyle}>كود دافع الضريبة:</span> {employee.tax_payer_id}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              الترقيات والزيادات الخاصة بك
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              هنا يمكنك رؤية تفاصيل الترقيات والزيادات الخاصة بك.
            </Typography>
            <Paper style={promotionSectionStyle}>
              <Typography variant="h6" style={promotionTextStyle}>
                الترقيات
              </Typography>
              {roleOrder.map((role, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  style={{
                    fontFamily: 'Cairo, sans-serif',
                    marginBottom: '8px',
                    fontWeight: employee.role === role ? 'bold' : 'normal',
                    color: employee.role === role ? '#ff5722' : 'gray',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {role}
                  {employee.role === role && (
                    <Flag style={{ marginLeft: '8px', color: '#ff5722' }} />
                  )}
                </Typography>
              ))}
            </Paper>
            <Typography variant="body1" style={countdownStyle}>
              الزيادة الخاصة بك بعد: {countdown}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              المهام المنفذه من اصل عدد المهام
            </Typography>
            <Typography
              variant="body1"
              style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
            >
              عدد المهام المنفذة: {completedTasksCount} من أصل {totalTasksCount}
            </Typography>
            <Box sx={{ width: '300px', height: '300px', margin: '0 auto' }}>
              <Doughnut data={doughnutData} options={doughnutOptions} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              التقيم السنوي للمهام
            </Typography>
            <Box sx={{ width: '100%', height: '400px', margin: '0 auto' }}>
              <Line data={lineData} options={lineOptions} />
            </Box>
          </Paper>
        </Grid>

        {/* Section for Deductions */}
        <Grid item xs={12}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '16px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              الخصومات
            </Typography>
            {deductions.length > 0 ? (
              deductions.map((deduction, index) => (
                <Box key={index} display="flex" alignItems="center" mt={2}>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: 'Cairo, sans-serif',
                      marginRight: '8px',
                      color: 'red',
                    }}
                  >
                    {`خصم ${deduction.reason}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: 'Cairo, sans-serif',
                      color: 'gray',
                    }}
                  >
                    {`(${deduction.date} - ${deduction.time}) - ${deduction.deduction} جنيه`}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  color: 'gray',
                  fontFamily: 'Cairo, sans-serif',
                  marginBottom: '8px',
                }}
              >
                لا توجد خصومات لهذا الشهر.
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* Section for Documents and Certificates */}
        <Grid item xs={12}>
          <Paper style={cardStyle}>
            <Typography variant="h6" style={{ marginBottom: '8px', fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>
              الوثائق والشهادات
            </Typography>
            {Array.isArray(employee.certificates) && employee.certificates.length > 0 ? (
              employee.certificates.map((certificate, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="flex-start" mt={2}>
                  {canPreviewFile(certificate) && (
                    <Button
                      onClick={() => handleOpenDialog(certificate)}
                      variant="contained"
                      color="primary"
                      startIcon={<VisibilityIcon style={{ marginLeft: "8px" }} />}
                      style={{ marginRight: '8px', backgroundColor: '#6fd943', fontFamily: "Cairo" }}
                    >
                      معاينة
                    </Button>
                  )}
                  <IconButton
                    href={`https://backendforerpsystem.zbooma.com/${certificate}`}
                    download
                    style={{ color: '#1e88e5', padding: 0 }}
                    aria-label="download certificate"
                  >
                    <Description />
                  </IconButton>
                  <Typography
                    variant="body1"
                    style={{ fontFamily: 'Cairo, sans-serif', marginLeft: '8px' }}
                  >
                    <a
                      href={`https://backendforerpsystem.zbooma.com/${certificate}`}
                      download
                      style={{ color: '#1e88e5', textDecoration: 'none' }}
                    >
                      تحميل الشهادة
                    </a>
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{ color: 'gray', fontFamily: 'Cairo, sans-serif', marginBottom: '8px' }}
              >
                لا توجد وثائق أو شهادات مسجلة.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Dialog for certificate preview */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>
          معاينة الشهادة
        </DialogTitle>
        <DialogContent>
          {currentCertificate && currentCertificate.endsWith('.pdf') && (
            <iframe
              src={`https://backendforerpsystem.zbooma.com/${currentCertificate}`}
              style={{ width: '100%', height: '500px' }}
              title="Certificate Preview"
            />
          )}
          {currentCertificate && (currentCertificate.endsWith('.docx') || currentCertificate.endsWith('.ppt') || currentCertificate.endsWith('.pptx')) && (
            <iframe
              src={`https://docs.google.com/viewer?url=https://backendforerpsystem.zbooma.com/${currentCertificate}&embedded=true`}
              style={{ width: '100%', height: '500px' }}
              title="Certificate Preview"
            />
          )}
          {currentCertificate &&
            !currentCertificate.endsWith('.pdf') &&
            !currentCertificate.endsWith('.docx') &&
            !currentCertificate.endsWith('.ppt') &&
            !currentCertificate.endsWith('.pptx') && (
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Cairo, sans-serif',
                  textAlign: 'center',
                  marginTop: '16px',
                }}
              >
                المعاينة غير مدعومة لهذا النوع من الملفات. يمكنك تنزيل الملف لمشاهدته.
              </Typography>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            style={{ fontFamily: 'Cairo, sans-serif' }}
          >
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>
      <TechnicalSkills />
    </Box>
  );
};

export default Profile;
