import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const Tickets = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [newTicket, setNewTicket] = useState({ title: '', description: '', priority: '', status: 'Open', username: '' });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get('username');

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`https://backendforerpsystem.zbooma.com/get_tickets.php?username=${username}`);
        setTickets(response.data.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, [username]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddTicket = async () => {
    setLoading(true);
    try {
      const ticketWithUsername = { ...newTicket, username };
      const response = await axios.post('https://backendforerpsystem.zbooma.com/add_ticket.php', ticketWithUsername);
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'تمت الإضافة بنجاح',
          text: 'تمت إضافة التذكرة الجديدة بنجاح',
        });
        setTickets([...tickets, ticketWithUsername]);
        setNewTicket({ title: '', description: '', priority: '', status: 'Open', username: '' });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: response.data.message,
        });
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: `Server Error: ${error.response.data.message}`,
        });
      } else if (error.request) {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: 'Network Error: Please check your connection.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: `Error: ${error.message}`,
        });
      }
      console.error('Error adding ticket:', error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Box p={2} style={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
      <Typography variant="h5" gutterBottom>التذاكر</Typography>
      <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginBottom: '16px' }}>
        إضافة تذكرة جديدة
      </Button>
      <Paper style={{ marginBottom: '16px', padding: '16px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>العنوان</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>الوصف</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>الأولوية</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>الحالة</TableCell>
                <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>تاريخ الإنشاء</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    لا توجد تذاكر لعرضها
                  </TableCell>
                </TableRow>
              ) : (
                tickets.map((ticket, index) => (
                  <TableRow key={index}>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{ticket.title}</TableCell>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{ticket.description}</TableCell>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{ticket.priority}</TableCell>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{ticket.status}</TableCell>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{ticket.created_at}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>إضافة تذكرة جديدة</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="العنوان"
            type="text"
            fullWidth
            value={newTicket.title}
            onChange={(e) => setNewTicket({ ...newTicket, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="الوصف"
            type="text"
            fullWidth
            value={newTicket.description}
            onChange={(e) => setNewTicket({ ...newTicket, description: e.target.value })}
          />
          <TextField
            margin="dense"
            label="الأولوية"
            type="text"
            fullWidth
            value={newTicket.priority}
            onChange={(e) => setNewTicket({ ...newTicket, priority: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>إلغاء</Button>
          <Button onClick={handleAddTicket} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'إضافة'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Tickets;
