import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Swal from 'sweetalert2';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const Dashboard = () => {
  const [meetingTimes, setMeetingTimes] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRegisteredToday, setIsRegisteredToday] = useState(false);
  const [posts, setPosts] = useState([]); // Initialize as empty array
  const [newComment, setNewComment] = useState({});
  const [likesData, setLikesData] = useState([]);
  const query = useQuery();

  useEffect(() => {
    const username = query.get('username');
    const todayDate = moment().format('YYYY-MM-DD');

    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          'https://backendforerpsystem.zbooma.com/getemployees.php'
        );
        const userData = response.data.data.find(
          (emp) => emp.username === username
        );
        if (userData) {
          setUser(userData);
        } else {
          console.error('No user found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setUserLoading(false);
      }
    };

    const fetchAttendanceRecords = async () => {
      try {
        const response = await axios.get(
          `https://backendforerpsystem.zbooma.com/get_attendance.php?username=${username}&date=${todayDate}`
        );
        const records = response.data.records || [];
        setAttendanceRecords(records);
        setIsRegisteredToday(records.length > 0);
      } catch (error) {
        console.error('Error fetching attendance records:', error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          'https://backendforerpsystem.zbooma.com/addPost.php'
        );
        setPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    const fetchLikesData = async () => {
      try {
        const response = await axios.get(
          'https://backendforerpsystem.zbooma.com/getLikes.php'
        );
        if (response.data.success) {
          setLikesData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching likes data:', error);
      }
    };

    fetchUserData();
    fetchAttendanceRecords();
    fetchPosts();
    fetchLikesData();
  }, [query]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAttendanceSelection = (type) => {
    const now = new Date();
    const timeString = now.toLocaleTimeString();
    const todayDate = moment().format('YYYY-MM-DD');

    axios
      .post('https://backendforerpsystem.zbooma.com/record_attendance.php', {
        username: user.username,
        type,
        time: timeString,
        date: todayDate,
      })
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'تم تسجيل الحضور',
            text: `تم تسجيل الحضور في الساعة ${timeString}`,
            confirmButtonText: 'حسناً',
            customClass: {
              confirmButton: 'my-custom-button-class',
            },
          });

          axios
            .get(
              `https://backendforerpsystem.zbooma.com/get_attendance.php?username=${user.username}&date=${todayDate}`
            )
            .then((res) => {
              const records = res.data.records || [];
              setAttendanceRecords(records);
              setIsRegisteredToday(records.length > 0);
            })
            .catch((err) =>
              console.error('Error fetching updated attendance records:', err)
            );
        } else {
          console.error('Failed to record attendance:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error recording attendance:', error);
      });

    handleCloseDialog();
  };

  const handleLikePost = (postId) => {
    if (!user || !user.username || !postId) {
      console.error('Missing required fields for liking the post', { postId, username: user?.username });
      return;
    }

    const formData = new URLSearchParams();
    formData.append('postId', postId);
    formData.append('username', user.username);

    axios.post('https://backendforerpsystem.zbooma.com/likePost.php', formData)
      .then(response => {
        if (response.data.success) {
          setPosts((prevPosts) =>
            prevPosts.map((post) => {
              if (post.id === postId) {
                return { ...post, hasLiked: true, likes: parseInt(post.likes || 0) + 1 };
              }
              return post;
            })
          );
        } else {
          console.error(response.data.message);
        }
      })
      .catch(error => {
        console.error('Error liking post:', error);
      });
  };

  const handleCommentChange = (event, postId) => {
    setNewComment((prevComments) => ({
      ...prevComments,
      [postId]: event.target.value,
    }));
  };

  const handleAddComment = (postId) => {
    const commentText = newComment[postId] || '';

    if (!user || !user.username || !postId || !commentText.trim()) {
      console.error('Missing required fields for adding a comment', { postId, username: user?.username, commentText });
      return;
    }

    const formData = new URLSearchParams();
    formData.append('postId', postId);
    formData.append('username', user.username);
    formData.append('commentText', commentText);

    axios.post('https://backendforerpsystem.zbooma.com/addComment.php', formData)
      .then(response => {
        if (response.data.success) {
          setPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.id === postId
                ? {
                  ...post,
                  comments: [
                    ...post.comments,
                    {
                      id: new Date().getTime(),
                      comment_text: commentText,
                      username: user.username,
                      created_at: new Date().toISOString(),
                    }
                  ]
                }
                : post
            )
          );
          setNewComment((prevComments) => ({
            ...prevComments,
            [postId]: '',
          }));
        } else {
          console.error(response.data.message);
        }
      })
      .catch(error => {
        console.error('Error adding comment:', error);
      });
  };

  const getLikesForPost = (postId) => {
    return likesData.filter(like => like.post_id === String(postId)).length;
  };

  const userHasLikedPost = (postId) => {
    return likesData.some(like => like.post_id === String(postId) && like.username === user?.username);
  };

  const currentTime = moment();
  const isWithinAttendanceHours = currentTime.isBetween(
    moment().startOf('day').add(8, 'hours'),
    moment().startOf('day').add(17, 'hours')
  );

  return (
    <Box p={3} style={{ direction: 'rtl' }}>
      <Grid container spacing={3} style={{ direction: 'rtl' }}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={{
              padding: '16px',
              marginBottom: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {userLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Avatar
                  src={`https://backendforerpsystem.zbooma.com/${user.photo}`}
                  alt={user.name}
                  style={{ width: 60, height: 60 }}
                />
                <Typography
                  variant="h6"
                  style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}
                >
                  {user.name}
                </Typography>
                {isWithinAttendanceHours && !isRegisteredToday && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    style={{ width: '100%', fontFamily: 'Cairo, sans-serif' }}
                  >
                    تسجيل الحضور
                  </Button>
                )}
                {attendanceRecords.length > 0 && (
                  <Box mt={2} style={{ textAlign: 'center' }}>
                    <Typography
                      variant="h6"
                      style={{ fontFamily: 'Cairo, sans-serif' }}
                    >
                      سجلات الحضور لليوم
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="right"
                              style={{ fontFamily: 'Cairo, sans-serif' }}
                            >
                              نوع الحضور
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontFamily: 'Cairo, sans-serif' }}
                            >
                              الوقت
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {attendanceRecords.map((record, index) => (
                            <TableRow key={index}>
                              <TableCell
                                align="right"
                                style={{ fontFamily: 'Cairo, sans-serif' }}
                              >
                                {record.type}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ fontFamily: 'Cairo, sans-serif' }}
                              >
                                {record.time}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </>
            )}
          </Paper>
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'Cairo, sans-serif',
              textAlign: 'right',
              margin: '16px',
            }}
          >
            التقويم الشهري
          </Typography>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Calendar
              localizer={localizer}
              events={meetingTimes.map((meeting) => ({
                title: meeting.title,
                start: new Date(meeting.start),
                end: new Date(meeting.end),
              }))}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500, cursor: 'pointer' }}
              selectable
              dayPropGetter={(date) => ({
                style: {
                  backgroundColor: moment(date).isSame(new Date(), 'day')
                    ? '#6fd943'
                    : null,
                },
              })}
              rtl
            />
          </Paper>
        </Grid>

        <Grid style={{ width: "100%", marginTop: "70px" }}>
          <Typography
            variant="h4"
            style={{
              fontFamily: 'Cairo, sans-serif',
              color: "#6fd943",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            المنشورات المهنية
          </Typography>
          {posts.map((post) => (
            <Paper
              key={post.id}
              elevation={3}
              style={{
                padding: "24px",
                marginBottom: "24px",
                borderRadius: "12px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {post.post_image_url && (
                <Box
                  style={{
                    width: "100%",
                    height: "400px",
                    overflow: "hidden",
                    borderRadius: "12px",
                    marginBottom: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`https://backendforerpsystem.zbooma.com/${post.post_image_url}`}
                    alt="Post"
                    style={{
                      width: "auto",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <Typography
                variant="h5"
                style={{ marginBottom: "12px", fontWeight: "bold", color: "#333", fontFamily: "cairo" }}
              >
                {post.post_title}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  marginBottom: "16px",
                  lineHeight: "1.6",
                  color: "#555",
                  fontSize: "1rem",
                  fontFamily: "cairo"
                }}
              >
                {post.post_content}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: "16px" }}
              >
                {new Date(post.created_at).toLocaleString('en-US', { hour12: true })}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography style={{ marginRight: "8px", color: "#333" }}>
                  {getLikesForPost(post.id)}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => handleLikePost(post.id)}
                    style={{ color: userHasLikedPost(post.id) ? 'gray' : '#6fd943' }}
                    disabled={userHasLikedPost(post.id)}
                  >
                    <ThumbUpIcon />
                    <Typography style={{ marginLeft: "4px", color: "#6fd943" }}>
                      {getLikesForPost(post.id)}
                    </Typography>
                  </IconButton>
                  <IconButton style={{ color: "#6fd943" }}>
                    <CommentIcon />
                  </IconButton>
                </Box>
                <Box display="flex" alignItems="center" flexGrow={1}>
                  <TextField
                    value={newComment[post.id] || ''}
                    onChange={(event) => handleCommentChange(event, post.id)}
                    variant="outlined"
                    size="small"
                    placeholder="Add a comment..."
                    style={{
                      flexGrow: 1,
                      marginRight: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  />
                  <Button
                    onClick={() => handleAddComment(post.id)}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6fd943",
                      marginLeft: "8px",
                    }}
                  >
                    Comment
                  </Button>
                </Box>
              </Box>
              <Box mt={2}>
                {post.comments.map((comment, index) => (
                  <Paper
                    key={index}
                    style={{
                      padding: "12px",
                      marginTop: "8px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body2" style={{ color: "#555" }}>
                      {comment.comment_text}
                    </Typography>
                    <Typography variant="caption" style={{ color: "#888" }}>
                      - {comment.username} on {new Date(comment.created_at).toLocaleString()}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Paper>
          ))}
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="attendance-dialog-title"
      >
        <DialogTitle
          id="attendance-dialog-title"
          style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}
        >
          تسجيل الحضور
        </DialogTitle>
        <DialogContent
          style={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}
        >
          اختر نوع الحضور
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleAttendanceSelection('الحضور من المنزل')}
            color="primary"
            variant="outlined"
            style={{ fontFamily: 'Cairo, sans-serif', width: '100%' }}
          >
            الحضور من المنزل
          </Button>
          <Button
            onClick={() => handleAttendanceSelection('الحضور من الموقع')}
            color="primary"
            variant="contained"
            style={{ fontFamily: 'Cairo, sans-serif', width: '100%' }}
          >
            الحضور من الموقع
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
